import { useQuery } from '@apollo/client';
import { GET_APPLY_INFORMATION } from '../queries/getApplyInformation';
import { GetApplyInformation } from '../types/getApplyInformation';

/**
 * Get accounts information
 *
 * @returns
 */
export const useGetApplyInformation = () => {
  return useQuery<GetApplyInformation>(GET_APPLY_INFORMATION);
};
