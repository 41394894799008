import * as Generated from 'gql'

export type Address = Generated.Address;

const FIELDS = 'city state streetAddress unit zipCode';

const EMPTY = Object.freeze<Address>({
    city: '',
    state: '',
    streetAddress: '',
    unit: '',
    zipCode: '',
  });
  
export const Address = {
    Fields: FIELDS,
    Empty: EMPTY,
  };
  