import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
  ServerError,
  ApolloError
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { uuidWithPrefix } from 'utilities/constants';
import { getRedirectURL } from 'auth/getRedirectURL';
interface CustomGraphQLError extends ApolloError {
  errorType?: string;
  errorInfo?: {
    errorCode?: string;
  };
  path?: readonly (string | number)[];
}

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_URI
});

export const AuthenticatedApolloProvider = ({
  onNetworkError,
  children
}: {
  onNetworkError?: () => void;
  children: ReactNode;
}) => {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const [token, setToken] = useState('');

  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated) {
        try {
          const authToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE
            }
          });
          return authToken;
        } catch (error) {
          logout({
            logoutParams: {
              returnTo: getRedirectURL()
            }
          });
        }
      }
      return '';
    };
    getToken().then((authToken) => {
      setToken(authToken);
    });
  }, [getAccessTokenSilently, isAuthenticated, logout]);

  const client = useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      if (!token) {
        logout({
          logoutParams: {
            returnTo: getRedirectURL()
          }
        });
        return null;
      }
      return {
        headers: {
          ...headers,
          'refId': uuidWithPrefix,
          'Authorization': `${token}`,
          'App-Origin-ID': 'customer-portal'
        }
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (networkError) {
        const serverError = networkError as ServerError;
        const detailedError = new Error(JSON.stringify(serverError));
        detailedError.name = `NetworkError on graphQL`;
        Sentry.captureException(detailedError, {
          tags: { refId: uuidWithPrefix }
        });
        switch (serverError?.statusCode) {
          case 401: // e.g existing token not valid anymore
            logout({
              logoutParams: {
                returnTo: getRedirectURL()
              }
            });
            break;
          default:
            onNetworkError?.();
        }
      }
      if (graphQLErrors) {
        graphQLErrors.map((error) => {
          const customError = error as unknown as CustomGraphQLError;
          const { message, path, errorType, errorInfo } = customError;
          const errorCode = errorInfo?.errorCode;
          const detailedGraphError = new Error(
            `${message} ${errorType} ${errorCode}`
          );
          detailedGraphError.name = `GraphQLError ${path?.[0]}`;
          (error as any).path = path;
          Sentry.captureException(detailedGraphError, {
            tags: { refId: uuidWithPrefix },
            extra: {
              path,
              message
            }
          });
        });
      }
    });

    const client: ApolloClient<any> = new ApolloClient({
      link: errorLink.concat(authLink.concat(httpLink)),
      cache: new InMemoryCache({
        typePolicies: {
          CustomerLease: {
            keyFields: ['accountId'] // Use accountId as cache key, instead of letting client generate key
          }
        }
      }),
      connectToDevTools:
        process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production' ? true : false
    });

    return client;
  }, [token, onNetworkError, logout]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
