import { useEffect, useLayoutEffect } from 'react';
import router, { useRouter } from 'next/router';
import { appConfig } from '../utilities/constants/app-config';
import { ReactlyticsContext } from '@one-snap/sparkles';
import amplitude from 'amplitude-js';
import { OneSnapTracking } from '@one-snap/tracking';
import { EventTypes } from './eventTypes';
import { PaymentMethod } from 'gql';
import { uuidWithPrefix } from 'utilities/constants';

interface GraphErrorValues {
  message: string;
  displayMessage?: string;
  application_id?: string;
  corelation_id?: string;
}

interface MakePaymentsErrorValues {
  message: string;
  displayMessage: string;
  application_id: string;
  corelation_id: string;
  paymentMethod: PaymentMethod;
  statusCode: any;
}

interface LogClickEventParams {
  click_value: string;
  click_type?: 'cta' | 'link' | 'dropdown' | 'radio button' | 'button';
  placement?: string;
}

export const useBrowserLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const tracking = new OneSnapTracking({
  vendor: {
    logEvent: (event: string, properties) => {
      amplitude.getInstance().logEvent(event, {
        page_url: document.location.href,
        page_path: window.location.pathname,
        subdomain_and_domain: document.location.host,
        event_source: 'customer-portal-v2',
        refId: uuidWithPrefix,
        ...properties
      });
    },
    setUserProperties: (properties) => {
      amplitude.getInstance().setUserProperties(properties);
    }
  }
});

export function TrackingProvider({ children }) {
  const { isFallback } = useRouter();

  // init vendor and listen routes changes
  useBrowserLayoutEffect(() => {
    if (isFallback) {
      return;
    }

    amplitude?.getInstance()?.init(appConfig.amplitudeApiKey, null, {
      includeReferrer: true,
      includeUtm: true,
      saveParamsReferrerOncePerSession: false,
      transport: 'beacon' // be able to trigger event after page exits or redirect
    });

    window['amplitude'] = amplitude?.getInstance();

    tracking?.setUserProperties({
      //   snap_user_id: userId,
      referer: document.referrer
    });

    logPageView(tracking);

    router?.events.on('routeChangeComplete', () => logPageView(tracking));

    return () => {
      router?.events.off('routeChangeComplete', () => logPageView(tracking));
    };
  }, [isFallback, router]);

  return (
    <ReactlyticsContext.Provider value={tracking}>
      {children}
    </ReactlyticsContext.Provider>
  );
}

export const logPageView = (tracking, page_name = document?.title) => {
  if (page_name !== '') {
    tracking?.logPageViewedEvent({ page_name: page_name });
  }
};
export const logApiErrors = ({ url, errorStatus, error, message }) => {
  tracking?.logEvent('api-error', {
    url,
    errorStatus,
    error,
    message,
    page_url: window.location.href,
    page_path: window.location.pathname,
    subdomain_and_domain: window.location.hostname
  });
};

export const logPaymentSdkError = ({
  message,
  displayMessage,
  code,
  name,
  type,
  codeType
}) => {
  tracking?.logEvent('payment-sdk-error', {
    message,
    displayMessage,
    code,
    name,
    type,
    codeType,
    page_url: window.location.href,
    page_path: window.location.pathname,
    subdomain_and_domain: window.location.hostname
  });
};

export const logGraphError = async (
  event: EventTypes,
  values: GraphErrorValues
) => {
  tracking?.logEvent(event, {
    product: 'LTO',
    snap_source: 'cp',
    ...values
  });
};

export const logMakePaymentEvent = async (
  event: EventTypes,
  values: MakePaymentsErrorValues
) => {
  tracking?.logEvent(event, {
    product: 'LTO',
    snap_source: 'cp',
    ...values
  });
};

export const logClickEvent = ({
  click_value,
  click_type,
  placement
}: LogClickEventParams) => {
  tracking?.logEvent('click', {
    click_value,
    click_type,
    placement,
    page_url: window.location.href,
    page_path: window.location.pathname,
    subdomain_and_domain: window.location.hostname
  });
};
