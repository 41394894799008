import React from 'react';
import { SnapLogo } from '@one-snap/next';
import { Button, IconClose } from '@one-snap/sparkles';
import { routes } from 'utilities/routing';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { NavItem } from './nav-item';
import { RenderIf } from '@one-snap/sparkles';

interface DrawerProps {
  isOpen: boolean;
  closeDrawer: () => void;
  logoutUser: () => void;
  loginUser: () => void;
  isAuthenticated: boolean;
}

export const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  closeDrawer,
  logoutUser,
  loginUser,
  isAuthenticated
}) => {
  const t = useTranslations();
  const { asPath } = useRouter();
  const handleLinkClick = () => {
    closeDrawer();
  };

  return isOpen ? (
    <div className="fixed top-0 left-0 w-full h-[calc(100dvh)] bg-white z-10">
      <div className="flex flex-row justify-between p-5">
        <SnapLogo size="sm" />
        <button onClick={closeDrawer} className="focus:outline-none">
          <IconClose iconColor="#353849" />
        </button>
      </div>
      <div className="flex flex-col pt-4 justify-between h-full">
        <RenderIf condition={isAuthenticated}>
          <ul className="p-5">
            <li className="py-2">
              <NavItem
                className="py-2 text-2xl font-semibold"
                href={routes.dashboard.home()}
                isActive={
                  asPath.startsWith(routes.dashboard.home()) &&
                  !asPath.endsWith(routes.dashboard.settings()) &&
                  !asPath.endsWith(routes.dashboard.help())
                }
                onClick={handleLinkClick}
                data-testid="dashboard-link"
              >
                {t('shared.menu.dashboard')}
              </NavItem>
            </li>
            <li className="py-2">
              <NavItem
                className="py-2 text-2xl font-semibold"
                href={routes.dashboard.help()}
                onClick={handleLinkClick}
                isActive={
                  asPath.startsWith(routes.dashboard.home()) &&
                  asPath.endsWith(routes.dashboard.help())
                }
              >
                {t('shared.menu.help')}
              </NavItem>
            </li>
            <li className="py-2">
              <NavItem
                className="py-2 text-2xl font-semibold"
                href={routes.dashboard.settings()}
                isActive={asPath.endsWith(routes.dashboard.settings())}
                onClick={handleLinkClick}
              >
                {t('shared.menu.settings')}
              </NavItem>
            </li>
          </ul>
          <div className="sticky bottom-0 w-full snap-background-color-sand px-4">
            <Button
              onClick={() => logoutUser()}
              variant="secondary"
              data-testid="logout-button"
              size="compact"
              className="py-2 text-lg my-4"
              fullWidth
              reactlytics={{
                clickType: 'CTA',
                clickValue: t('shared.menu.logout'),
                placement: 'body'
              }}
            >
              {t('shared.menu.logout')}
            </Button>
          </div>
        </RenderIf>
        <RenderIf condition={!isAuthenticated}>
          <div className="absolute bottom-0 w-full snap-background-color-sand px-4">
            <Button
              onClick={() => loginUser()}
              variant="primary"
              data-testid="login-button"
              size="compact"
              className="sticky py-2 text-lg my-4 w-full"
            >
              {t('shared.menu.login')}
            </Button>
          </div>
        </RenderIf>
      </div>
    </div>
  ) : null;
};
