import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { paymentInputsReducer } from './slices/payment-inputs';
import { userInputsReducer } from './slices/user-inputs';
import { paymentOutputsReducer } from './slices/payment-outputs';

const combinedReducer = combineReducers({
  paymentInputs: paymentInputsReducer,
  paymentOutputs: paymentOutputsReducer,
  userInputs: userInputsReducer
});

export const store = configureStore({
  reducer: (state, action) => {
    if (action.type === 'hydrate') {
      const nextState = {
        ...state,
        ...action.payload
      };
      return nextState;
    }
    return combinedReducer(state, action);
  },
  // add logger middleware to see state logs in console, alternative - Redux Chrome extension
  middleware: (getDefaultMiddleware) =>
    process.env.NEXT_PUBLIC_DISABLE_REDUX_LOGS
      ? getDefaultMiddleware()
      : getDefaultMiddleware().concat(logger)
});

export type RootState = ReturnType<typeof combinedReducer>;

export type AppDispatch = typeof store.dispatch;
