/**
 * Takes milliseconds string and returns a formatted datetime string
 *
 * @param value
 * @param locale
 * @param format
 * @returns
 */
export const formatMillisecondsDate = (
  value: string,
  locale: Intl.LocalesArgument = DEFAULT_LOCALE,
  format: Intl.DateTimeFormatOptions = DEFAULT_FORMAT
) => {
  const dateMs = parseInt(value);
  const date = new Date(dateMs);
  return dateMs ? date.toLocaleDateString(locale, format) : '';
};

/**
 * Takes a string date like 01/01/2021 and returns a formatted datetime string
 *
 * @param value
 * @param locale
 * @param format
 * @returns
 */
export function formatDate(
  value: string,
  locale: Intl.LocalesArgument = DEFAULT_LOCALE,
  format: Intl.DateTimeFormatOptions = DEFAULT_FORMAT
): string {
  const date = new Date(value);
  return value ? date.toLocaleDateString(locale, format) : '';
}

const DEFAULT_LOCALE: Intl.LocalesArgument = 'en-US';

const DEFAULT_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
};
