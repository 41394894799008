import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type InputValues = Partial<{
  email: string;
}>;

export interface UserInputsState {
  values: InputValues;
}

const initialState: UserInputsState = {
  values: {
    email: null
  }
};

export const userInputsStateSlice = createSlice({
  name: 'userInputs',
  initialState,
  reducers: {
    persistUserInputs: (state, action: PayloadAction<InputValues>) => {
      state.values = { ...state.values, ...action.payload };
    }
  }
});

export const { persistUserInputs } = userInputsStateSlice.actions;

export const userInputsReducer = userInputsStateSlice.reducer;
