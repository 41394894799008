import React, { useState } from 'react';
import Image from 'next/image';
import { Drawer } from 'components/features/layout/drawer';
import { routes } from 'utilities/routing';
import { useAuth } from 'auth/useAuth';
import { useRouter } from 'next/router';

export const NavigationBarMobile = ({ isViewMobile }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { logout, isAuthenticated } = useAuth();
  const { push } = useRouter();

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const logoutUser = () => {
    logout();
    closeDrawer();
  };

  const loginUser = () => {
    push(routes.login());
  };

  return (
    <div className="sticky top-0 z-10 snap-background-color-sand p-4">
      <div className="flex justify-between">
        <Image
          src="https://ui-cms-s3.snapfinance.com/CMS/Snap_2023_Logo.svg"
          alt="logo"
          width={81}
          height={36}
        />
        <button
          onClick={openDrawer}
          className="snap-color-green ml-4 p-2 rounded-md focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>
      <Drawer
        isOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        logoutUser={logoutUser}
        isAuthenticated={isAuthenticated}
        loginUser={loginUser}
      />
    </div>
  );
};
