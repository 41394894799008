import { useRouter } from 'next/router';
import { useAuth } from './useAuth';
import { ReactNode, memo, useEffect } from 'react';
import { AppSpinner } from '@one-snap/sparkles';
import { routes } from 'utilities/routing';

/**
 * This component is used to redirect the user to the login page if they are not authenticated.
 * Used from app root
 */
const AuthRedirectComponent = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const { pathname, push } = useRouter();

  useEffect(() => {
    const isDashboardRoute =
      pathname.startsWith('/dashboard') && pathname !== routes.dashboard.help();

    if (!isAuthenticated && !isLoading && isDashboardRoute) {
      push(routes.login());
    }
  }, [isAuthenticated, isLoading, pathname]);

  if (isLoading) {
    return <AppSpinner />;
  }

  return children;
};

export const AuthRedirect = memo(AuthRedirectComponent);
