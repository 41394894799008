/**
 * This file centralizes all the Customer portal routes, it can be used to fill the href attribute of the Link component
 * or used together with push or replace methods of the Router component.
 */
export const routes = {
  login: () => '/',
  otp: () => '/authentication/otp',
  snapLoanCustomer: () => '/authentication/snap-loan-customer',
  unverifiedEmail: () => '/authentication/unverified-email',
  returningCustomer: () => '/authentication/returning-customer',
  notAlice: () => '/authentication/not-found',
  unavailableError: () => '/error/unavailable',
  dashboard: {
    home: () => '/dashboard',
    settings: () => '/dashboard/settings',
    help: () => '/dashboard/help',
    account: {
      details: (query: AccountRoutesParams) => `/dashboard/${query?.accountId}`,
      transactions: (query: AccountRoutesParams) =>
        `/dashboard/${query?.accountId}/transactions`,
      ownership: (query: AccountRoutesParams) =>
        `/dashboard/${query?.accountId}/ownership-options`,
      agreement: (query: AccountRoutesParams) =>
        `/dashboard/${query?.accountId}/agreement`,
      paymentAmount: (query: AccountRoutesParams) =>
        `/dashboard/${query?.accountId}/payments/payment-amount`,
      paymentAmountPayOffInFull: (query: AccountRoutesParams) =>
        `/dashboard/${query?.accountId}/payments/payment-amount/pay-off-in-full`,
      paymentMethod: (query: AccountRoutesParams) =>
        `/dashboard/${query?.accountId}/payments/payment-method`,
      paymentDate: (query: AccountRoutesParams) =>
        `/dashboard/${query?.accountId}/payments/payment-date`,
      paymentToday: (query: AccountRoutesParams) =>
        `/dashboard/${query?.accountId}/payments/payment-today`,
      paymentScheduled: (query: AccountRoutesParams) =>
        `/dashboard/${query?.accountId}/payments/payment-scheduled`
    }
  }
};

interface AccountRoutesParams {
  accountId: string | string[];
}
