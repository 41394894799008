import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { Button, RenderIf } from '@one-snap/sparkles';
import { routes } from 'utilities/routing';
import { useAuth } from 'auth/useAuth';
import { NavItem } from './nav-item';

export const NavigationBar = ({ isViewMobile }) => {
  const { logout, isAuthenticated } = useAuth();
  const t = useTranslations();
  const { asPath, push } = useRouter();

  const logoutUser = () => {
    logout();
  };

  const loginUser = () => {
    push(routes.login());
  };

  const loginPaths = ['/login', '/#/login'];

  const renderLayout = !loginPaths.includes(asPath); // Use currentPath instead of router.asPath

  return (
    renderLayout && (
      <header className="border-b border-gray-300 py-8 snap-background-color-sand md:px-4">
        <div className="flex items-center justify-between xl:max-w-7xl xl:mx-auto">
          <Image
            src="https://ui-cms-s3.snapfinance.com/CMS/Snap_2023_Logo.svg"
            alt="logo"
            width={isViewMobile ? 81 : 108}
            height={isViewMobile ? 36 : 48}
          />
          <nav className="flex items-center w-auto">
            <ul className="text-base text-gray-600 flex justify-between">
              <RenderIf condition={isAuthenticated}>
                <NavItem
                  href={routes.dashboard.home()}
                  isActive={
                    asPath.startsWith(routes.dashboard.home()) &&
                    !asPath.endsWith(routes.dashboard.settings()) &&
                    !asPath.endsWith(routes.dashboard.help())
                  }
                >
                  {t('shared.menu.dashboard')}
                </NavItem>
                <NavItem
                  isActive={asPath.endsWith(routes.dashboard.help())}
                  href={routes.dashboard.help()}
                >
                  {t('shared.menu.help')}
                </NavItem>
                <NavItem
                  isActive={asPath.endsWith(routes.dashboard.settings())}
                  href={routes.dashboard.settings()}
                >
                  {t('shared.menu.settings')}
                </NavItem>
                <Button
                  onClick={() => logoutUser()}
                  variant="primary"
                  data-testid="logout-button"
                  size="compact"
                  className="py-2 text-lg ml-10"
                  reactlytics={{
                    clickType: 'CTA',
                    clickValue: t('shared.menu.logout'),
                    placement: 'body'
                  }}
                >
                  {t('shared.menu.logout')}
                </Button>
              </RenderIf>
              <RenderIf condition={!isAuthenticated}>
                <Button
                  onClick={() => loginUser()}
                  variant="primary"
                  data-testid="login-button"
                  size="compact"
                  className="py-2 text-lg ml-10"
                >
                  {t('shared.menu.login')}
                </Button>
              </RenderIf>
            </ul>
          </nav>
        </div>
      </header>
    )
  );
};
