import { gql } from '@apollo/client';
import { Address } from 'lib/models/Address';
import { Phone } from 'lib/models/Phone';

export const GET_APPLY_INFORMATION = gql`
  query MyQuery {
    getApplyInformation {
      customerInformation {
        firstName
        lastName
        phoneNumbers {
          ${Phone.Fields}
        }
        email
        address {
          ${Address.Fields}
        }
      }
    }
  }
`;
