import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslations } from 'next-intl';
import { CountDownTimer } from 'components/ui/count-down-timer';
import { SnapDialog, SpinnerRing } from '@one-snap/sparkles';
import { colors } from '@one-snap/sparkles-styles';
import { Events } from '@one-snap/toolbox';
import { useAuth } from 'auth/useAuth';

const USER_EVENTS = ['scroll', 'keypress', 'click', 'mousemove'];
export const SessionInactivity = memo(function _SessionInactivity({
  inactivityTimer = 5,
  onTimeOut
}: {
  inactivityTimer: number;
  onTimeOut: () => void;
}) {
  const [isInactive, setIsInactive] = useState(false);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const timerInterval = useRef(null);
  const timer = inactivityTimer * 60 * 1000;
  const t = useTranslations();
  const { isAuthenticated } = useAuth();

  const timerCheck = useCallback(() => {
    timerInterval.current = setTimeout(() => {
      setIsInactive(true);
    }, timer);
  }, [timer]);

  const resetTimer = useCallback(() => {
    if (timerInterval.current) {
      setIsInactive(false);
      clearTimeout(timerInterval.current);
      timerCheck();
    }
  }, [timerCheck]);

  useEffect(() => {
    const cleanupFns = USER_EVENTS.map((event) => {
      Events.listen(window, event, resetTimer);
    });
    timerCheck();

    return () => {
      cleanupFns.forEach((cleanup) => cleanup);
      clearTimeout(timerInterval.current);
    };
  }, [resetTimer, timerInterval, timerCheck]);

  useEffect(() => {
    if (isTimeUp) {
      onTimeOut();
    }
  }, [isTimeUp, onTimeOut]);

  return (
    <>
      {isAuthenticated && (
        <SnapDialog
          open={isInactive}
          onClose={() => setIsInactive(false)}
          title=""
          id="inactivity-modal"
          className="text-center px-6"
          data-testid="inactivity-modal"
        >
          <h2 className="text-SnapDarkBlue headingMHeavy mb-1">
            {t('shared.inactivityModal.heading')}
          </h2>
          <div className="text-SnapGrey400 bodyFont mb-2">
            <p>{t('shared.inactivityModal.body')}</p>
            <div className="relative p-6 mt-6">
              <SpinnerRing
                arcColor={colors.SnapGreen}
                className="absolute m-auto inset-x-0 inset-y-0 h-[120px] w-[120px] opacity-40"
              />
              <CountDownTimer
                className={'text-4xl py-0 md:py-2'}
                timerStart={60}
                onTimeOut={setIsTimeUp}
              />
            </div>
          </div>
        </SnapDialog>
      )}
    </>
  );
});
