import Link from 'next/link';
import cn from 'classnames';
import { ReactNode } from 'react';

export function NavItem({
  href,
  className,
  children,
  isActive,
  onClick
}: NavItemProps) {
  return (
    <Link
      className={cn(
        'py-2 px-5 font-semibold hover:text-SnapGreen',
        isActive && 'text-SnapGreen',
        className
      )}
      href={href}
      onClick={onClick}
      data-testid="nav-item"
    >
      {children}
    </Link>
  );
}

interface NavItemProps {
  href: string;
  isActive?: boolean;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}
