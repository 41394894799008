const generateUUID = () => {
    // Get the current time in milliseconds since Unix epoch
    const currentTime = Date.now().toString(16);
  
    // Generate a random 16-character hexadecimal string
    const randomHex = Array.from({ length: 16 }, () =>
      Math.floor(Math.random() * 16).toString(16)
    ).join('');
  
    // Combine the timestamp and random hex string
    return `${currentTime}-${randomHex}`;
  };
  
export const uuidWithPrefix = `customer-portal-${generateUUID()}`;