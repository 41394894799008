import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import { sparklesMaterialTheme } from '@one-snap/sparkles';
import { NextIntlClientProvider } from 'next-intl';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import '../styles/global.scss';
import '../styles/styles.css';
import { store } from '../state/store';
import { appConfig } from '../utilities/constants/app-config';
import { AuthRedirect } from 'auth/auth-redirect';
import 'node_modules/@one-snap/sparkles/style.css';
import { AuthenticatedApolloProvider } from 'api/client';
import { AppMessage } from '@one-snap/next';
import { NetworkErrorAlert } from 'components/ui/alerts/network-error';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { AppErrorBoundary } from 'components/ui/app-error-boundary';
import { TrackingProvider } from 'utilities/tracking-provider';
import { Layout } from 'components/features/layout/layout';
import { useAuth } from 'auth/useAuth';
import { SessionInactivity } from 'components/features/session-inactivity/session-inactivity';
import { SmartBanner } from 'components/features/smart-banner/smart-banner';
import { AuthenticatedGuard } from 'guards/authenticated-guard';
import { ErrorTrackingContext, ErrorTrackingSeverity } from '@one-snap/core';
import * as Sentry from '@sentry/nextjs';
import { getRedirectURL } from 'auth/getRedirectURL';
import { uuidWithPrefix } from 'utilities/constants';
import { Events } from '@one-snap/toolbox';
import { useRouter } from 'next/router';

const sentryErrorLevels: {
  [key in ErrorTrackingSeverity]: Sentry.SeverityLevel;
} = {
  error: 'error',
  debug: 'debug',
  info: 'info',
  log: 'log',
  warn: 'warning',
  fatal: 'fatal'
};

function CustomApp({ Component, pageProps }: AppProps) {
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const onNetworkError = useCallback(
    () => setHasNetworkError(true),
    [setHasNetworkError]
  );

  const router = useRouter();

  // More info: https://stackoverflow.com/a/42063482/1153149
  useEffect(() => {
    const detachPageshow = Events.listen(
      window,
      'pageshow',
      (e: PageTransitionEvent) => {
        const navigationEntries = performance.getEntriesByType('navigation');
        if (
          e.persisted ||
          (navigationEntries.length > 0 &&
            navigationEntries[0].entryType === 'back_forward')
        ) {
          window.location.reload();
        }
      }
    );

    return () => {
      detachPageshow();
    };
  }, []);

  return (
    <NextIntlClientProvider
      messages={pageProps?.messages ?? {}}
      locale={router.locale}
    >
      <ErrorTrackingContext.Provider
        value={{
          logFetchError: (method, path, httpStatus, severity) => {
            Sentry.withScope((scope) => {
              scope.setLevel(sentryErrorLevels[severity]);
              const error = new Error(`${method} ${path} ${httpStatus}`);
              const shortPath = path?.split('.com').pop();
              error.name = `${shortPath} - ${httpStatus} `;
              Sentry.captureException(error, {
                tags: { refId: uuidWithPrefix }
              });
            });
          }
        }}
      >
        <Auth0Provider
          domain={appConfig.auth0Domain as string}
          clientId={appConfig.auth0ClientId as string}
          authorizationParams={{
            redirect_uri: getRedirectURL(),
            audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE
          }}
          useRefreshTokens={true}
          cacheLocation="localstorage"
          useRefreshTokensFallback={true} // fixing missing_refresh_token error
        >
          <AppErrorBoundary>
            <AuthenticatedApolloProvider onNetworkError={onNetworkError}>
              <Provider store={store}>
                <AuthRedirect>
                  <Head>
                    <title>Customer Portal</title>
                  </Head>
                  <main className="app">
                    <SmartBanner />
                    <TrackingProvider>
                      <ThemeProvider theme={sparklesMaterialTheme}>
                        <AppMessage>
                          <AuthenticatedGuard>
                            <LayoutResolver noLayout={pageProps?.noLayout}>
                              <Component {...pageProps} />
                            </LayoutResolver>
                          </AuthenticatedGuard>
                          <NetworkErrorAlert
                            hasError={hasNetworkError}
                            onDismissed={() => setHasNetworkError(false)}
                          />
                        </AppMessage>
                      </ThemeProvider>
                    </TrackingProvider>
                  </main>
                </AuthRedirect>
              </Provider>
            </AuthenticatedApolloProvider>
          </AppErrorBoundary>
        </Auth0Provider>
      </ErrorTrackingContext.Provider>
    </NextIntlClientProvider>
  );
}

export default CustomApp;

function LayoutResolver({
  children,
  noLayout
}: PropsWithChildren & { noLayout: boolean }) {
  const { logout } = useAuth();

  const logoutUser = () => {
    logout();
  };

  return noLayout ? (
    <>
      {children}
      <SessionInactivity inactivityTimer={15} onTimeOut={logoutUser} />
    </>
  ) : (
    <Layout>
      {children}
      <SessionInactivity inactivityTimer={15} onTimeOut={logoutUser} />
    </Layout>
  );
}
