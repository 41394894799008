import { useCallback } from 'react';
import { formatDate, formatMillisecondsDate } from './formatDate';
import { useRouter } from 'next/router';

/**
 * Formats datetimes using the locale from the NextJS router
 *
 * @returns
 */
export function useFormatDate() {
  const { locale } = useRouter();

  const _formatDate = useCallback(
    (value: string, format?: Intl.DateTimeFormatOptions) =>
      formatDate(value, locale, format),
    [locale]
  );

  const _formatMillisecondsDate = useCallback(
    (value: string, format?: Intl.DateTimeFormatOptions) =>
      formatMillisecondsDate(value, locale, format),
    [locale]
  );

  return {
    /**
     * Takes a string date like 01/01/2021 and returns a formatted date string
     */
    formatDate: _formatDate,
    /**
     * Takes milliseconds string and returns a formatted date string
     */
    formatMillisecondsDate: _formatMillisecondsDate
  };
}
