import React, { useEffect } from 'react';

export interface RouteGuardProps {
  children: React.ReactNode;
  validate: () => Promise<boolean> | boolean;
}

/**
 * Generic route guard component, used to validate the route, if not valid, it will not render the children
 *
 * @param validate Function to be called to validate the route
 * @returns
 */
export function RouteGuard({ children, validate }: RouteGuardProps) {
  const [validated, setValidated] = React.useState(false);
  useEffect(() => {
    const validateFn = async () => {
      const valid = await validate();
      setValidated(valid);
    };
    validateFn();
  }, [validate]);

  if (validated) {
    return <>{children}</>;
  }
  return null;
}
