import { useEffect, useRef } from 'react';

/**
 * Keep track of previous value vrs current value, helpful for state comparation
 *
 * @param value
 * @returns
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
