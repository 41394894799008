import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentMethod } from 'gql';
import { PaymentOptionType } from 'lib/models/payments/types/paymentTypes';

export type PaymentInputs = Partial<{
  paymentAmount: number;
  paymentMethodId: string;
  paymentMethodType: PaymentMethod;
  paymentDate: number;
  paymentType: string;
  paymentOptionType: PaymentOptionType;
  paymentStatus: string;
  isFullPayment: boolean;
}>;

interface PaymentInputsState {
  values: PaymentInputs;
}

export const initialState: PaymentInputsState = {
  values: {
    paymentAmount: null,
    paymentMethodId: null,
    paymentMethodType: null,
    paymentDate: null,
    paymentOptionType: null,
    paymentType: null,
    paymentStatus: null,
    isFullPayment: false
  }
};

export const paymentInputsStateSlice = createSlice({
  name: 'paymentInputs',
  initialState,
  reducers: {
    persistInputs: (state, action: PayloadAction<PaymentInputs>) => {
      state.values = { ...state.values, ...action.payload };
    },
    resetInputs: (state) => {
      state.values = {
        paymentAmount: null,
        paymentMethodId: null,
        paymentMethodType: null,
        paymentDate: null,
        paymentOptionType: null,
        paymentType: null,
        paymentStatus: null,
        isFullPayment: false
      };
    }
  }
});

export const { persistInputs, resetInputs } = paymentInputsStateSlice.actions;

export const paymentInputsReducer = paymentInputsStateSlice.reducer;
