import { useEffect } from 'react';
import { useCountDownTimer } from 'utilities/useCountDownTimer';
import cn from 'classnames';

export const CountDownTimer = ({
  timerStart,
  className,
  onTimeOut
}: {
  timerStart: number;
  className?: string;
  onTimeOut?: (boolean) => void;
}) => {
  const { countDown, isTimeUp } = useCountDownTimer({
    timerStart
  });

  useEffect(() => {
    if (isTimeUp) {
      onTimeOut(true);
    }
  }, [isTimeUp, onTimeOut]);

  return (
    <span
      data-testid="count-down"
      className={cn('text-SnapDarkBlue font-semibold', className)}
    >
      {countDown}
    </span>
  );
};
