import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentMethod } from 'gql';
import { PaymentOptionType } from 'lib/models/payments/types/paymentTypes';

export type PaymentOutputs = Partial<{
  offer: {  // temporary, replace with graph type SeenOffer
    id: string
    amf: number
    apr: number
    maxLine: number
    priority: number
    expiresAt: string
    invitationCode: string
    url: string
    category: string
  }
}>;

interface PaymentOutputsState {
  values: PaymentOutputs;
}

export const initialState: PaymentOutputsState = {
  values: {
    offer: {
      id: '',
      amf: null,
      apr: null,
      maxLine: null,
      priority: null,
      expiresAt: '',
      invitationCode: '',
      url: '',
      category: '',
    }
  }
};

export const paymentOutputsStateSlice = createSlice({
  name: 'paymentOutputs',
  initialState,
  reducers: {
    persistOutputs: (state, action: PayloadAction<PaymentOutputs>) => {
      state.values = { ...state.values, ...action.payload };
    },
    resetOutputs: (state) => {
      state.values = {
        offer: {
          id: '',
          amf: null,
          apr: null,
          maxLine: null,
          priority: null,
          expiresAt: '',
          invitationCode: '',
          url: '',
          category: '',
        }
      };
    }
  }
});

export const { persistOutputs, resetOutputs } = paymentOutputsStateSlice.actions;

export const paymentOutputsReducer = paymentOutputsStateSlice.reducer;
