import { useAppMessage } from '@one-snap/next';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { usePrevious } from 'utilities/usePrevious';

/**
 * Global application network error messaging
 *
 * @param param0
 * @returns
 */
export function NetworkErrorAlert({
  hasError,
  onDismissed
}: {
  hasError: boolean;
  onDismissed?: () => void;
}) {
  const { showErrorMessage, isOpened } = useAppMessage();
  const previousIsOpened = usePrevious(isOpened);
  const t = useTranslations();

  /** show error message */
  useEffect(() => {
    if (hasError) {
      showErrorMessage({
        message: t('shared.error.networkError')
      });
    }
  }, [t, hasError, showErrorMessage]);

  /** hide error message, executes callback */
  useEffect(() => {
    if (previousIsOpened && !isOpened) {
      onDismissed?.();
    }
  }, [previousIsOpened, isOpened, hasError, onDismissed]);

  return null;
}
