import { useAuth } from 'auth/useAuth';
import { RouteGuard } from 'components/ui/route-guard';
import { useRouter } from 'next/router';
import { ReactNode, useCallback } from 'react';
import { routes } from 'utilities/routing';

/**
 * Prevent children to be rendered if there is no token
 *
 * @param param0
 * @returns
 */
export function AuthenticatedGuard({ children }: { children: ReactNode }) {
  const { pathname } = useRouter();
  const { isAuthenticated } = useAuth();
  const isDashboard = pathname.startsWith(routes.dashboard.home());
  const allowedPaths = [routes.dashboard.help()];

  const validate = useCallback(() => {
    if (!isDashboard || isAuthenticated || allowedPaths.includes(pathname)) {
      return true;
    }
    return false;
  }, [allowedPaths, isAuthenticated, isDashboard, pathname]);

  return <RouteGuard validate={validate}>{children}</RouteGuard>;
}
