import React, { useState } from 'react';
import { IconClose } from '@one-snap/sparkles';
import { useTranslations } from 'next-intl';
import {
  isMobile,
  isIOS,
  isSafari,
  isAndroid,
  browserName
} from 'react-device-detect';

export function SmartBanner() {
  const t = useTranslations('shared.smartBanner');

  const [isBannerVisible, setBannerVisible] = useState(() => {
    const isClosed = sessionStorage.getItem('isBannerClosed');
    return isClosed !== 'true';
  });

  const handleCloseBanner = () => {
    setBannerVisible(false);
    sessionStorage.setItem('isBannerClosed', 'true');
  };

  // Specific device/browser checks
  if (!isMobile || (isIOS && isSafari)) {
    return null;
  }

  // Banner visibility check
  if (!isBannerVisible) {
    return null;
  }

  let appLink, storeText;
  if (isAndroid) {
    appLink = t('androidLink');
    storeText = t('inGooglePlay');
  } else if (isIOS) {
    appLink = t('appStoreLink');
    storeText = 'inAppStore';
  }

  return (
    <div
      className={`flex flex-row justify-between items-center bg-SnapWhite pl-2 p-2`}
    >
      <div className="flex items-center">
        <button
          aria-label={t('close')}
          onClick={handleCloseBanner}
          className="mr-3"
        >
          <IconClose iconColor="" />
        </button>
        <div className="bg-smart-banner-logo w-16 h-16 rounded-xl"></div>
        <div className="ml-4">
          <p className="font-semibold text-black text-sm">{t('title')}</p>
          <p className="text-xs text-SnapGrey400">{t('description')}</p>
          <p className="text-xs text-SnapGrey400 -mt-[10px]">
            {t('get')} - {storeText}
          </p>
        </div>
      </div>
      {appLink && (
        <a href={appLink} className="mr-1">
          {t('view')}
        </a>
      )}
    </div>
  );
}
