import { Footer } from './footer';
import { NavigationBarMobile } from './navigation-bar-mobile';
import { Greeting } from './greeting';
import { NavigationBar } from './navigation-bar';
import { PropsWithChildren, memo } from 'react';
import { Banner } from '../../ui/banner';
import { useIsViewMobile } from '../../../utilities/useIsViewMobile';
import { useAuth } from 'auth/useAuth';
import { RenderIf } from '@one-snap/sparkles';

export const Layout = memo(function _Layout({ children }: PropsWithChildren) {
  const { isAuthenticated } = useAuth();
  const { isViewMobile } = useIsViewMobile();

  return (
    <div className="min-h-screen flex flex-col">
      {isViewMobile ? (
        <NavigationBarMobile isViewMobile={isViewMobile} />
      ) : (
        <NavigationBar isViewMobile={isViewMobile} />
      )}
      <Banner />
      <RenderIf condition={isAuthenticated}>
        <Greeting />
      </RenderIf>

      <div className="lg:px-4">
        <main className="flex flex-col lg:flex-row mx-auto md:justify-between lg:max-w-7xl lg:space-x-4 lg:mb-4">
          {children}
        </main>
      </div>
      {isAuthenticated && <Footer year={new Date().getFullYear()} />}
    </div>
  );
});
