import { UnavailableComponent } from 'components/ui/unavailable-component';
import { GetStaticPropsContext } from 'next';

import React from 'react';

export default function UnavailableError() {
  return <UnavailableComponent />;
}

export async function getStaticProps({
  locale
}: GetStaticPropsContext<any, any>) {
  return {
    props: {
      noLayout: true,
      messages: {
        shared: { ...require(`../../i18n/shared/${locale}.json`) }
      }
    }
  };
}
