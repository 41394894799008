import { Button } from '@one-snap/sparkles';
import { useAuth } from 'auth/useAuth';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

export const UnavailableComponent = () => {
  const t = useTranslations();
  const { logout } = useAuth();

  return (
    <div
      className="flex justify-center flex-col align-items-center h-screen"
      data-testid="unavailable-container"
    >
      <div className="max-w-md p-4 text-center">
        <Image src={'/assets/error.svg'} width={200} height={200} alt={'404'} />
        <h1 className="headingMHeavy lg:headingL text-SnapDarkBlue">
          {t('shared.error.application')}
        </h1>
        <p>{t('shared.error.applicationSummary')}</p>
      </div>
      <div className="max-w-md p-4 text-center">
        <Button
          className="mt-2 mb-8"
          data-testid="logout-btn"
          onClick={logout}
          type="button"
          reactlytics={{
            clickType: 'CTA',
            clickValue: t('shared.menu.logout'),
            placement: 'body'
          }}
        >
          {t('shared.menu.logout')}
        </Button>
      </div>
    </div>
  );
};
