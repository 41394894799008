import { useState, useEffect } from 'react';

export const useCountDownTimer = ({
  timerStart = 60
}: {
  timerStart: number;
}) => {
  const [countDown, setCountDown] = useState(timerStart);
  const [isTimeUp, setIsTimeUp] = useState(false);

  useEffect(() => {
    const interval =
      countDown &&
      setInterval(() => {
        setCountDown((count) => count - 1);
      }, 1000);

    if (!countDown) {
      setIsTimeUp(true);
    }
    return () => clearInterval(interval);
  }, [countDown]);

  return { countDown, isTimeUp };
};
